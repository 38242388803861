// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microsites-js": () => import("./../src/pages/microsites.js" /* webpackChunkName: "component---src-pages-microsites-js" */),
  "component---src-pages-microsites-price-list-js": () => import("./../src/pages/microsites/price-list.js" /* webpackChunkName: "component---src-pages-microsites-price-list-js" */),
  "component---src-pages-microsites-terms-and-conditions-js": () => import("./../src/pages/microsites/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-microsites-terms-and-conditions-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-product-detail-js": () => import("./../src/templates/product-detail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */)
}


import "./public/static/css/styles.css"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { ShopProvider } from "./src/context/shop-context"

export const wrapRootElement = ({ element }) => (
  <ShopProvider>{element}</ShopProvider>
)

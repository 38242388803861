import React, { useState, useEffect } from "react"
import Client from "shopify-buy"

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: process.env.SHOPIFY_DOMAIN,
})

export const defaultValues = {
  client,
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  checkCoupon: () => {},
  removeCoupon: () => {},
  checkout: {
    lineItems: [],
  },
}

export const ShopContext = React.createContext(defaultValues)

export const ShopProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [isLoading, setLoading] = useState(false)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    initializeCheckout()
  }, [])

  const getNewId = async () => {
    try {
      const newCheckout = await client.checkout.create()
      if (isBrowser) {
        localStorage.setItem("checkout_id", newCheckout.id)
      }
      return newCheckout
    } catch (e) {
      console.error(e)
    }
  }

  const initializeCheckout = async () => {
    try {
      const currentCheckoutId = isBrowser
        ? localStorage.getItem("checkout_id")
        : null
      let newCheckout = null

      if (currentCheckoutId) {
        newCheckout = await client.checkout.fetch(currentCheckoutId)
        if (newCheckout.completedAt) {
          newCheckout = await getNewId()
        }
      } else {
        newCheckout = await getNewId()
      }
      setCheckout(newCheckout)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const addProductToCart = async variantId => {
    try {
      setLoading(true)
      const lineItems = [
        {
          variantId: variantId,
          quantity: 1,
        },
      ]

      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      )

      setCheckout(newCheckout)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const removeProductFromCart = async (lineItemId) => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ])

      setCheckout(newCheckout)
      setLoading(false)
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  const checkCoupon = async (coupon) => {
    setLoading(true)
    const newCheckout = await client.checkout.addDiscount(checkout.id, coupon)
    setCheckout(newCheckout)
    setLoading(false)
  }

  const removeCoupon = async (coupon) => {
    setLoading(true)
    const newCheckout = await client.checkout.removeDiscount(
      checkout.id,
      coupon
    )
    setCheckout(newCheckout)
    setLoading(false)
  }

  const updateLineItem = (client, checkoutID, lineItemID, quantity) => {
    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) }
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(response => {
        setCheckout(response);
      });
  }

  const updateQuantity = async (lineItemID, quantity) => {
    if (!quantity) {
      return;
    }
    await updateLineItem(client, checkout.id, lineItemID, quantity);
    setLoading(false);
  }

  return (
    <ShopContext.Provider
      value={{
        ...defaultValues,
        checkout,
        addProductToCart,
        removeProductFromCart,
        checkCoupon,
        removeCoupon,
        isLoading,
        setLoading,
        updateQuantity,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}
